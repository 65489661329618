import { createWebHistory, createRouter } from "vue-router";
import routerTest from "@/routes/test.js";

const routes = [
	{
    name: "splash",
		path: "/",
		component: () => import("@/views/main/Splash.vue"),
		meta: { layout: "WideLayout" }
  },
	{
    name: "photos",
		path: "/photos",
		component: () => import("@/views/album/Photos.vue"),
  },
  {
    path: '/main',
		component: () => import("@/views/main/Main.vue"),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import("@/views/main/Home.vue"),
      },
      {
        path: 'album',
        name: 'album',
        component: () => import("@/views/album/Album.vue"),
      },
      {
        path: 'education',
        name: 'education',
        component: () => import("@/views/education/Lectures.vue"),
      },

      {
        path: 'animals',
        name: 'animals',
        component: () => import("@/views/play/animals.vue"),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import("@/views/settings/Settings.vue"),
      },
    ]
  },
	{
    name: "photo",
		path: "/photo",
    component: () => import("@/views/album/AnalyzePhoto.vue"),
  },
	{
    name: "lecture",
		path: "/lecture",
    component: () => import("@/views/education/Education.vue"),
  },
	{
    name: "lectureV1",
		path: "/lecture/v1",
    component: () => import("@/views/education/Lecture.vue"),
  },

  {
    name: "animal",
		path: "/animal",
    component: () => import("@/views/play/Play.vue"),
  },
  ...routerTest,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

var prevRouterPath;
router.beforeEach((to, from, next) => {
  prevRouterPath = from.fullPath; 
  next()
})

export { router, prevRouterPath };
