import { createStore } from "vuex";
import site from "@/store/site.js";

import idb from '@/js/idb';

export default createStore({
  modules: {
    site,
  },
  state () {
    return {
      isNavOpen: false,
      depth1ImageData: {},
      depth2ImageData: {},
      cache: true,
      devMode: false,
      enableSR: true,
      provider: "webgpu",
      device: "gpu",
    }
  },
  mutations: {
    setCache(state, value) {
      state.cache = value;
    },
    setDevMode(state, value) {
      state.devMode = value;
    },
    setEnableSR(state, value) {
      state.enableSR = value;
    },
    setProvider(state, value) {
      state.provider = value;
    },
    setDevice(state, value) {
      state.device = value;
    },
    setDepth2ImageData(state, value) {
      state.depth2ImageData = value;
    },
    setIsNavOpen(state, value) {
      state.isNavOpen = value;
    },
    toggleNav(state, value) {
      state.isNavOpen = !state.isNavOpen;
    },
  }
});
