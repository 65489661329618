<template>
  <router-view></router-view>
</template>

<script setup>
import '@/assets/css/preset.css';
</script>

<style>
body {
  margin: 0;
}
#app {
  /* text-align: center; */
  width : 100vw;
  height : 100vh;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #000;;
  animation: background-fade 1s forwards;
  position: relative;
}
@keyframes background-fade {
  from {background-color: #000;}
  to {background-color: #fff;}
}
</style>